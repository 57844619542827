//import jwtDecode from 'jwt-decode';
import { Cookies } from "react-cookie";
import {parseISOString} from './timeHelper';


/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {

    try {

        const user = getLoggedInUser();

        if (!user) {
            return false;
        }
    //const decoded = jwtDecode(user.token);
//console.log(user);
        //const currentTime = Date.now() / 1000;
        const expireTimestamp = parseISOString(user.expire);
        const dateTime = new Date().getTime();
        const timestamp = Math.floor(dateTime / 1000);

        if (timestamp>=expireTimestamp-300) {
          //console.warn('access token expired');
          return false;
        } else {
          //console.log('access token valid');
          return true;
        }


    } catch (error) {
        return false;
    }
/*
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    }
    else {
        return true;
    }
*/
}

const isUserAdmin = () => {
//console.log("ych isUserAdmin()");
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    if (user.role === "admin") {
        return true;
    } else {
        return false;
    }
}

const isUserManager = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    if (user.role === "admin") {
        return false;
    } else if (user.role === "manager") {
        return true;
    } else {
        return false;
    }
}

const isUserOperator = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    if (user.role === "admin") {
        return false;
    } else if (user.role === "operator") {
        return true;
    } else {
        return false;
    }
}

const isUserSale = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    if (user.role === "sale") {
        return true;
    //} else if (user.role === "operator") {
        //return true;
    } else {
        return false;
    }
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies(); 
    const user = cookies.get("user");
//console.log("getLoggedInUser");
//console.log(user);
    return user ? (typeof(user) == 'object'? user: JSON.parse(user)) : null;
}

const getToken = () => {
    const cookies = new Cookies(); 
    const token = cookies.get("token");
    const expire = cookies.get("expire");

    if (token===undefined) return null;

//return null;
//console.log(token);
//console.log(expire);
    if (token==="") return null;

    //const currentTime = Date.now() / 1000;
    const expireTimestamp = parseISOString(expire);
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    if (timestamp>=expireTimestamp-300) {
        //console.warn('access token expired');
        return null;
    } else {
        return token;
    }
}

const getUserToken = () => {
    const user = getLoggedInUser();

//console.log(user);
    if (!user) {
        return null;
    }
    //const currentTime = Date.now() / 1000;
    const expireTimestamp = parseISOString(user.expire);
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    if (timestamp>=expireTimestamp-300) {
        console.warn('access token expired');
        return null;
    } else {
        return user.token;
    }
}

export { isUserAuthenticated, isUserAdmin, isUserOperator, isUserManager, isUserSale, getLoggedInUser, getToken, getUserToken };

