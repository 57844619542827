// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    //REGISTER_USER,
    //REGISTER_USER_SUCCESS,
    //REGISTER_USER_FAILED,
    //FORGET_PASSWORD,
    //FORGET_PASSWORD_SUCCESS,
    //FORGET_PASSWORD_FAILED,
    RESET_PASSWORD,
    MY_USER_INFO,
    MY_USER_UPDATE,
} from '../../constants/actionTypes';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (email: string, password: string): AuthAction => ({
    type: LOGIN_USER,
    payload: { email, password }
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error
});

/*
export const registerUser = (fullname: string, email: string, password: string): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, password }
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error
});
*/

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history }
});

/*
export const forgetPassword = (email: string): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: { email }
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});
*/

export const resetPassword = (admin_id: int, key: string, new_password:string, response_receive:any): AuthAction => ({
    type: RESET_PASSWORD,
    payload: { admin_id, key, new_password, response_receive }
});

export const myUserInfo = ( response_receive:any ): AuthAction => ({
    type: MY_USER_INFO,
    payload: { response_receive }
});

export const myUserUpdate = (name: string, old_password:string, password:string, response_receive:any): AuthAction => ({
    type: MY_USER_UPDATE,
    payload: { name, old_password, password, response_receive }
});

