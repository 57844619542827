/* COMPETITION */
export const COMPETITION_INFO = 'COMPETITION_INFO';
export const COMPETITION_SUBMIT = 'COMPETITION_SUBMIT';




/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
//export const REGISTER_USER = 'REGISTER_USER';
//export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
//export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
//export const FORGET_PASSWORD = 'FORGET_PASSWORD';
//export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
//export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const MY_USER_INFO = 'MY_USER_INFO';
export const MY_USER_UPDATE = 'MY_USER_UPDATE';


/* USER */
//export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
//export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const LOGIN_HISTORY = 'LOGIN_HISTORY';
export const LOGIN_HISTORY_SUCCESS = 'LOGIN_HISTORY_SUCCESS';
export const LOGIN_HISTORY_FAILED = 'LOGIN_HISTORY_FAILED';

/* DASHBOARD */
/*
export const USER_WALLET_SUMMARY = 'USER_WALLET_SUMMARY';
export const USER_WALLET_SUMMARY_SUCCESS = 'USER_WALLET_SUMMARY_SUCCESS';
export const USER_WALLET_SUMMARY_FAILED = 'USER_WALLET_SUMMARY_FAILED';
export const SYSTEM_WALLET_SUMMARY = 'SYSTEM_WALLET_SUMMARY';
export const SYSTEM_WALLET_SUMMARY_SUCCESS = 'SYSTEM_WALLET_SUMMARY_SUCCESS';
export const SYSTEM_WALLET_SUMMARY_FAILED = 'SYSTEM_WALLET_SUMMARY_FAILED';

export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';
*/

/* MANAGEMENT */
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILED = 'USER_LIST_FAILED';
export const USER_INFO = 'USER_INFO';
export const USER_ADD = 'USER_ADD';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_LOG = 'USER_LOG';
export const USER_LOG_SUCCESS = 'USER_LOG_SUCCESS';
export const USER_LOG_FAILED = 'USER_LOG_FAILED';

/* ADVERTISER */
export const ADVERTISER_LIST = 'ADVERTISER_LIST';
export const ADVERTISER_LIST_SUCCESS = 'ADVERTISER_LIST_SUCCESS';
export const ADVERTISER_LIST_FAILED = 'ADVERTISER_LIST_FAILED';
export const ADVERTISER_INFO = 'ADVERTISER_INFO';
export const ADVERTISER_ADD = 'ADVERTISER_ADD';
export const ADVERTISER_UPDATE = 'ADVERTISER_UPDATE';

/* SALE */
export const SALE_LIST = 'SALE_LIST';
export const SALE_LIST_SUCCESS = 'SALE_LIST_SUCCESS';
export const SALE_LIST_FAILED = 'SALE_LIST_FAILED';

/* CAMPAIGN */
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST';
export const CAMPAIGN_INFO = 'CAMPAIGN_INFO';
export const CAMPAIGN_ADD = 'CAMPAIGN_ADD';
export const CAMPAIGN_STATS = 'CAMPAIGN_STATS';
export const CAMPAIGN_STATS_SUCCESS = 'CAMPAIGN_STATS_SUCCESS';
export const CAMPAIGN_STATS_FAILED = 'CAMPAIGN_STATS_FAILED';
export const CAMPAIGN_REPORT_DOWNLOAD = 'CAMPAIGN_REPORT_DOWNLOAD';

/* ASSET */
export const ASSET_LIST = 'ASSET_LIST';
export const ASSET_LIST_SUCCESS = 'ASSET_LIST_SUCCESS';
export const ASSET_LIST_FAILED = 'ASSET_LIST_FAILED';
export const ASSET_INFO = 'ASSET_INFO';
export const ASSET_STATS = 'ASSET_STATS';
export const ASSET_ADD = 'ASSET_ADD';
export const ASSET_UPDATE = 'ASSET_UPDATE';

/* CREATIVE */
export const CREATIVE_LIST = 'CREATIVE_LIST';
export const CREATIVE_UPLOAD = 'CREATIVE_UPLOAD';

