// @flow
//import { Cookies } from "react-cookie";
//import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { all, call, fork, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getToken } from '../../helpers/authUtils';

import {
    COMPETITION_INFO,
    COMPETITION_SUBMIT,
} from '../../constants/actionTypes';


//import {
//} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

/*
function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
*/


/**
 * competition info 
 * @param {*} param0 
 */
function* competitionInfo({ payload: { response_receive } }) {
    try {

        let token = getToken();
//console.log(token);
//console.log(field);
        let options;
        if (token!==null) {
            options = {
                body: JSON.stringify({

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {
                body: JSON.stringify({

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json'
                    }
            };
        }
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/lounge/competition.info', options);
            if (response_receive!==null) {
                response_receive(response);
            }

        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
                default: response.message = 'Unknown error'; break;

            }

            if (response_receive!==null) {
                response_receive(response);
            }
        }

    } catch (error) { }
}

/**
 * competition submit 
 * @param {*} param0 
 */
function* competitionSubmit({ payload: { number, response_receive } }) {
    try {

        let token = getToken();
//console.log(field);
        let options;
            options = {
                body: JSON.stringify({
                    "number": number
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/lounge/competition.submit', options);
            if (response_receive!==null) {
                response_receive(response);
            }

        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
                default: response.message = 'Unknown error'; break;

            }

            if (response_receive!==null) {
                response_receive(response);
            }
        }

    } catch (error) { }
}


export function* watchCompetitionInfo():any {
    yield takeEvery(COMPETITION_INFO, competitionInfo);
}

export function* watchCompetitionSubmit():any {
    yield takeEvery(COMPETITION_SUBMIT, competitionSubmit);
}

function* competitionSaga():any {
    yield all([
        fork(watchCompetitionInfo),
        fork(watchCompetitionSubmit),
    ]);
}

export default competitionSaga;

