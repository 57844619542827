// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken, getLoggedInUser } from '../../helpers/authUtils';

import {
    LOGIN_USER,
    LOGOUT_USER,
    //REGISTER_USER,
    //FORGET_PASSWORD,
    RESET_PASSWORD,
    MY_USER_INFO,
    MY_USER_UPDATE,
} from '../../constants/actionTypes';


import {
    loginUserSuccess,
    loginUserFailed,
    //registerUserSuccess,
    //registerUserFailed,
    //forgetPasswordSuccess,
    //forgetPasswordFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * Sets the session
 * @param {*} user 
 */
const setSession = (user) => {
    let cookies = new Cookies();
    if (user)
        cookies.set("user", JSON.stringify(user), { path: "/" });
    else
        cookies.remove("user");
};
/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/admin/login', options);
        if (response.success===false) {
            yield put(loginUserFailed(response.message));
            setSession(null);
        } else {
            setSession(response);
            yield put(loginUserSuccess(response));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(loginUserFailed(message));
        //yield put(loginUserFailed('unknown_error'));
        setSession(null);
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {

        let token = getUserToken();
        let myUser = getLoggedInUser();
        myUser.expire = "2000-11-28T03:48:42.717898";
        setSession(myUser);
console.log(history);
console.log(token);
console.log("logout");
        const options = {
            body: JSON.stringify({}),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

/*
headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token,
        }),
*/

        setSession(null);
        token = getUserToken();
console.log(token);
        const response = yield call(fetchJSON, '/admin/logout', options);
console.log(response);
        history.push("/login");
        //yield put(loginUserSuccess(response));

        //yield call(() => {
            //history.push("/login");
        //});
    } catch (error) { }
}

/**
 * Register the user
 */
/*
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(registerUserFailed(message));
    }
}
*/

/**
 * forget password
 */
/*
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}
*/

/**
 * reset user's password
 * @param {*} param0
 */
function* resetPassword({ payload: { admin_id, key, new_password, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "admin_id": admin_id,
                "key": key,
                "password": new_password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/reset.password', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

        //yield put(loginUserSuccess(response));

        //yield call(() => {
            //history.push("/login");
        //});
    } catch (error) { }
}

/**
 * get My User info response
 * @param {*} param0
 */
function* myUserInfo({ payload: { response_receive } }) {
    try {

        let token = getUserToken();

        let options = {}
            options = {
                body: JSON.stringify({

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        try {
            const response = yield call(fetchJSON, '/admin/info', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }   
            
    } catch (error) { }
}                   

/**
 * My User Update
 * @param {*} param0
 */
function* myUserUpdate({ payload: { name, old_password, password, response_receive } }) {
    try {

        let token = getUserToken();
        let options = {};

        if (password==="") {
        options = {
            body: JSON.stringify({ 
                "name": name,
                "old_password": old_password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
        } else {
        options = {
            body: JSON.stringify({ 
                "name": name,
                "old_password": old_password,
                "password": password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
        }

        try {
            const response = yield call(fetchJSON, '/admin/update', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

export function* watchLoginUser():any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser():any {
    yield takeEvery(LOGOUT_USER, logout);
}

/*
export function* watchRegisterUser():any {
    yield takeEvery(REGISTER_USER, register);
}
*/

/*
export function* watchForgetPassword():any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}
*/

export function* watchResetPassword():any {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchMyUserInfo():any {
    yield takeEvery(MY_USER_INFO, myUserInfo);
}

export function* watchMyUserUpdate():any {
    yield takeEvery(MY_USER_UPDATE, myUserUpdate);
}

function* authSaga():any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        //fork(watchRegisterUser),
        //fork(watchForgetPassword),
        fork(watchResetPassword),
        fork(watchMyUserInfo),
        fork(watchMyUserUpdate),
    ]);
}

export default authSaga;
