import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

//import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';
//import { getLoggedInUser } from './helpers/authUtils';

//import { isUserOperator } from './helpers/authUtils';
//import { isUserManager } from './helpers/authUtils';
//import { isUserAdmin } from './helpers/authUtils';
//import { isUserSale } from './helpers/authUtils';

// lazy load all the views
const DailyCompetition = React.lazy(() => import('./pages/DailyCompetition'));
const RedirectURI = React.lazy(() => import('./pages/RedirectURI'));

//const ADNetworkReportPage = React.lazy(() => import('./pages/ADNetworkReportPage'));
//const ADReportPage = React.lazy(() => import('./pages/ADReportPage'));
//const ADReportDownloadPage = React.lazy(() => import('./pages/ADReportDownloadPage'));
//const ADReportDetailDownloadPage = React.lazy(() => import('./pages/ADReportDetailDownloadPage'));
//const AdvertiserPage = React.lazy(() => import('./pages/AdvertiserPage'));
//const AdvertiserEditPage = React.lazy(() => import('./pages/AdvertiserEditPage'));
//const AdvertiserEditPreviewPage = React.lazy(() => import('./pages/AdvertiserEditPreviewPage'));
//const AdvertiserEditUpdatedPage = React.lazy(() => import('./pages/AdvertiserEditUpdatedPage'));
//const AdvertiserAddPage = React.lazy(() => import('./pages/AdvertiserAddPage'));
//const AdvertiserAddPreviewPage = React.lazy(() => import('./pages/AdvertiserAddPreviewPage'));
//const AdvertiserAddUpdatedPage = React.lazy(() => import('./pages/AdvertiserAddUpdatedPage'));
//const MyAssetPage = React.lazy(() => import('./pages/MyAssetPage'));
//const AssetAddPage = React.lazy(() => import('./pages/AssetAddPage'));
//const AssetAddPreviewPage = React.lazy(() => import('./pages/AssetAddPreviewPage'));
//const AssetAddUpdatedPage = React.lazy(() => import('./pages/AssetAddUpdatedPage'));
//const AssetEditPage = React.lazy(() => import('./pages/AssetEditPage'));
//const AssetEditPreviewPage = React.lazy(() => import('./pages/AssetEditPreviewPage'));
//const AssetEditUpdatedPage = React.lazy(() => import('./pages/AssetEditUpdatedPage'));
//const CampaignAddPage = React.lazy(() => import('./pages/CampaignAddPage'));
//const CampaignAddUpdatedPage = React.lazy(() => import('./pages/CampaignAddUpdatedPage'));
//const ImpressionReportPage = React.lazy(() => import('./pages/ImpressionReportPage'));
//const SalesManPage = React.lazy(() => import('./pages/SalesManPage'));
//const SalesManEditPage = React.lazy(() => import('./pages/SalesManEditPage'));
//const SalesManEditPreviewPage = React.lazy(() => import('./pages/SalesManEditPreviewPage'));
//const SalesManEditUpdatedPage = React.lazy(() => import('./pages/SalesManEditUpdatedPage'));
//const SalesManAddPage = React.lazy(() => import('./pages/SalesManAddPage'));
//const SalesManAddPreviewPage = React.lazy(() => import('./pages/SalesManAddPreviewPage'));
//const SalesManAddUpdatedPage = React.lazy(() => import('./pages/SalesManAddUpdatedPage'));
//const LogPage = React.lazy(() => import('./pages/LogPage'));
//const UserPage = React.lazy(() => import('./pages/UserPage'));
//const UserEditPage = React.lazy(() => import('./pages/UserEditPage'));
//const UserEditPreviewPage = React.lazy(() => import('./pages/UserEditPreviewPage'));
//const UserEditUpdatedPage = React.lazy(() => import('./pages/UserEditUpdatedPage'));
//const UserResetPasswordPreviewPage = React.lazy(() => import('./pages/UserResetPasswordPreviewPage'));
//const UserResetPasswordUpdatedPage = React.lazy(() => import('./pages/UserResetPasswordUpdatedPage'));
//const UserAddPage = React.lazy(() => import('./pages/UserAddPage'));
//const UserAddPreviewPage = React.lazy(() => import('./pages/UserAddPreviewPage'));
//const UserAddUpdatedPage = React.lazy(() => import('./pages/UserAddUpdatedPage'));
//const MyAccountEditPage = React.lazy(() => import('./pages/MyAccountEditPage'));
//const MyAccountEditPreviewPage = React.lazy(() => import('./pages/MyAccountEditPreviewPage'));
//const MyAccountEditUpdatedPage = React.lazy(() => import('./pages/MyAccountEditUpdatedPage'));

//const FundProfitPage = React.lazy(() => import('./pages/FundProfitPage'));
//const UserMgmtAllAccounts = React.lazy(() => import('./pages/UserMgmtAllAccounts'));
//const TaskListPage = React.lazy(() => import('./pages/TaskListPage'));
//const RMListPage = React.lazy(() => import('./pages/RMListPage'));
//const RMTeamListPage = React.lazy(() => import('./pages/RMTeamListPage'));
//const RMTeamInfoPage = React.lazy(() => import('./pages/RMTeamInfoPage'));
//const UserListPage = React.lazy(() => import('./pages/UserListPage'));
//const RMCreateNewPage = React.lazy(() => import('./pages/RMCreateNewPage'));
//const RMUpdateUserPage = React.lazy(() => import('./pages/RMUpdateUserPage'));
//const FundWithdrawPage = React.lazy(() => import('./pages/FundWithdrawPage'));
//const RMUserListPage = React.lazy(() => import('./pages/RMUserListPage'));
//const RMUserDetailUserPage = React.lazy(() => import('./pages/RMUserDetailUserPage'));
//const RMUserCreateNewPage = React.lazy(() => import('./pages/RMUserCreateNewPage'));

//const Dashboard = React.lazy(() => import('./pages/Dashboard'));
//const UserMgmt = React.lazy(() => import('./pages/UserMgmt'));
//const UserDetail = React.lazy(() => import('./pages/UserDetail'));
//const UserResetPassword = React.lazy(() => import('./pages/UserResetPassword'));
//const ReportWOTA = React.lazy(() => import('./pages/Report-WOTA'));
// const Report100EP = React.lazy(() => import('./pages/Report-100EP'));
//const TransferDeposit = React.lazy(() => import('./pages/TransferDeposit'));
//const TransferWithdraw = React.lazy(() => import('./pages/TransferWithdraw'));
//const GenerateForm = React.lazy(() => import('./pages/GenerateForm'));
//const RegisterRecent = React.lazy(() => import('./pages/RegisterRecent'));
//const DepositRecent = React.lazy(() => import('./pages/DepositRecent'));
//const WithdrawRecent = React.lazy(() => import('./pages/WithdrawRecent'));
//const DepositFromWota = React.lazy(() => import('./pages/DepositFromWota'));
//const FormPost = React.lazy(() => import('./pages/FormPost'));
//const BulkEmail = React.lazy(() => import('./pages/BulkEmail'));

// lazy load id format views. 
//const Form = React.lazy(() => import('./pages/Form'));
//const User = React.lazy(() => import('./pages/User'));

// auth
//const Login = React.lazy(() => import('./pages/auth/Login'));
//const Logout = React.lazy(() => import('./pages/auth/Logout'));
//const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));
//const Register = React.lazy(() => import('./pages/account/Register'));
//const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

//const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));
//const AccountMgmt = React.lazy(() => import('./pages/account/AccountMgmt'));

// statistics
//const Stats = React.lazy(() => import('./pages/log/Stats.js'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    //const isAuthTokenValid = isUserAuthenticated();
    //if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      //return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    //}

    //const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
//console.log(roles);
//console.log(loggedInUser.role);
//console.log("ych");
//console.log(roles);


    return <Redirect to={{ pathname: '/competition/daily' }} />

/*
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page

      if (isUserManager()) {
          return <Redirect to={{ pathname: '/advertiser/list' }} />
      } 
      if (isUserOperator()) {
          return <Redirect to={{ pathname: '/asset/my' }} />
      } 
      if (isUserSale()) {
          return <Redirect to={{ pathname: '/asset/my' }} />
      } 
      if (isUserAdmin()) {
          return <Redirect to={{ pathname: '/user/list' }} />
      } 
      return <Redirect to={{ pathname: '/report/adnetwork' }} />
    }

    // authorised so return component
    return <Component {...props} />
*/
  }} />
)

const routes = [
  // auth and account
  //{ path: '/login', name: 'Login', component: Login, route: Route },
  //{ path: '/logout', name: 'Logout', component: Logout, route: Route },
  //{ path: '/reset-password', name: 'ResetPassword Password', component: ResetPassword, route: Route },
//   { path: '/register', name: 'Register', component: Register, route: Route },
  //{ path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },

  //{ path: '/account', name: 'Account Management', component: AccountMgmt, route: Route },
  //{ path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },


  { path: '/competition/daily', name: 'DailyCompetition', component: DailyCompetition, route: Route },
  { path: '/competition/redirect', name: 'RedirectURI', component: RedirectURI, route: Route },

  //{ path: '/report/adnetwork', name: 'ADNetworkReportPage', component: ADNetworkReportPage, route: PrivateRoute, roles: ['operator','sale','manager'], title: '聯播網成效報表' },
  //{ path: '/report/ad', name: 'ADReportPage', component: ADReportPage, route: PrivateRoute, roles: ['operator','sale','manager'], title: '廣告成效報表' },
  //{ path: '/report/ad-download/:id', name: 'ADReportDownloadPage', component: ADReportDownloadPage, route: PrivateRoute, roles: ['operator','sale','manager'], title: '廣告成效報表-下載總表' },
  //{ path: '/report/ad-detail-download/:id', name: 'ADReportDetailDownloadPage', component: ADReportDetailDownloadPage, route: PrivateRoute, roles: ['manager'], title: '廣告成效報表-下載明細' },
  //{ path: '/report/impression/:id', name: 'ImpressionReportPage', component: ImpressionReportPage, route: PrivateRoute, roles: ['operator','sale','manager'], title: '廣告成效' },
//
  //{ path: '/asset/my', name: 'MyAssetPage', component: MyAssetPage, route: PrivateRoute, roles: ['operator','sale','manager'], title: '我的廣告活動' },
  //{ path: '/asset/add', name: 'AssetAddPage', component: AssetAddPage, route: PrivateRoute, roles: ['operator','manager'], title: '新增廣告活動' },
  //{ path: '/asset/add-preview', name: 'AssetAddPreviewPage', component: AssetAddPreviewPage, route: PrivateRoute, roles: ['operator','manager'], title: '新增廣告活動' },
  //{ path: '/asset/add-updated', name: 'AssetAddUpdatedPage', component: AssetAddUpdatedPage, route: PrivateRoute, roles: ['operator','manager'], title: '新增廣告活動' },
  //{ path: '/asset/edit/:id', name: 'AssetEditPage', component: AssetEditPage, route: PrivateRoute, roles: ['operator','manager','sale'], title: '編輯廣告活動' },
  //{ path: '/asset/edit-preview', name: 'AssetEditPreviewPage', component: AssetEditPreviewPage, route: PrivateRoute, roles: ['operator','manager'], title: '編輯廣告活動' },
  //{ path: '/asset/edit-updated', name: 'AssetEditUpdatedPage', component: AssetEditUpdatedPage, route: PrivateRoute, roles: ['operator','manager'], title: '編輯廣告活動' },
  //{ path: '/campaign/add', name: 'CampaignAddPage', component: CampaignAddPage, route: PrivateRoute, roles: ['operator','manager'], title: '新增廣告組合' },
  //{ path: '/campaign/add-updated', name: 'CampaignAddUpdatedPage', component: CampaignAddUpdatedPage, route: PrivateRoute, roles: ['operator','manager'], title: '新增廣告組合' },
//
//
  //{ path: '/advertiser/list', name: 'AdvertiserPage', component: AdvertiserPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/edit/:id', name: 'AdvertiserEditPage', component: AdvertiserEditPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/edit-preview', name: 'AdvertiserEditPreviewPage', component: AdvertiserEditPreviewPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/edit-updated', name: 'AdvertiserEditUpdatedPage', component: AdvertiserEditUpdatedPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/add', name: 'AdvertiserAddPage', component: AdvertiserAddPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/add-preview', name: 'AdvertiserAddPreviewPage', component: AdvertiserAddPreviewPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/advertiser/add-updated', name: 'AdvertiserAddUpdatedPage', component: AdvertiserAddUpdatedPage, route: PrivateRoute, roles: ['manager'], title: '廣告主管理' },
  //{ path: '/salesman/list', name: 'SalesManPage', component: SalesManPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/edit/:id', name: 'SalesManEditPage', component: SalesManEditPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/edit-preview', name: 'SalesManEditPreviewPage', component: SalesManEditPreviewPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/edit-updated', name: 'SalesManEditUpdatedPage', component: SalesManEditUpdatedPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/add', name: 'SalesManAddPage', component: SalesManAddPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/add-preview', name: 'SalesManAddPreviewPage', component: SalesManAddPreviewPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },
  //{ path: '/salesman/add-updated', name: 'SalesManAddUpdatedPage', component: SalesManAddUpdatedPage, route: PrivateRoute, roles: ['manager'], title: '業務人員管理' },


  //{ path: '/user/list', name: 'UserPage', component: UserPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/edit/:id', name: 'UserEditPage', component: UserEditPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/edit-preview', name: 'UserEditPreviewPage', component: UserEditPreviewPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/edit-updated', name: 'UserEditUpdatedPage', component: UserEditUpdatedPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/reset-preview', name: 'UserResetPasswordPreviewPage', component: UserResetPasswordPreviewPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/reset-updated', name: 'UserResetPasswordUpdatedPage', component: UserResetPasswordUpdatedPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/add', name: 'UserAddPage', component: UserAddPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/add-preview', name: 'UserAddPreviewPage', component: UserAddPreviewPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/add-updated', name: 'UserAddUpdatedPage', component: UserAddUpdatedPage, route: PrivateRoute, roles: ['admin'], title: '使用者管理' },
  //{ path: '/user/log', name: 'LogPage', component: LogPage, route: PrivateRoute, roles: ['admin'], title: '使用紀錄' },


  //{ path: '/account/edit', name: 'MyAccountEditPage', component: MyAccountEditPage, route: PrivateRoute, roles: ['operator','admin','sale','manager'], title: '我的帳戶' },
  //{ path: '/account/edit-preview', name: 'MyAccountEditPreviewPage', component: MyAccountEditPreviewPage, route: PrivateRoute, roles: ['operator','admin','sale','manager'], title: '我的帳戶' },
  //{ path: '/account/edit-updated', name: 'MyAccountEditUpdatedPage', component: MyAccountEditUpdatedPage, route: PrivateRoute, roles: ['operator','admin','sale','manager'], title: '我的帳戶' },





  // other pages
  //{ path: '/team/list', name: 'RMTeamListPage', component: RMTeamListPage, route: PrivateRoute, roles: ['operator','rm','supervisor'], title: 'Team List' },
  //{ path: '/team/info/:id', name: 'RMTeamInfoPage', component: RMTeamInfoPage, route: PrivateRoute, roles: ['supervisor','operator','rm'], title: 'Team Detail Info', },
  //{ path: '/task/list', name: 'TaskListPage', component: TaskListPage, route: PrivateRoute, roles: ['supervisor', 'operator'], title: 'Task List' },
  //{ path: '/rm/list', name: 'RMListPage', component: RMListPage, route: PrivateRoute, roles: ['operator','supervisor'], title: 'RM List' },
  //{ path: '/fund/withdraw', name: 'FundWithdrawPage', component: FundWithdrawPage, route: PrivateRoute, roles: ['operator'], title: 'Fund Withdraw' },
  //{ path: '/fund/profit', name: 'FundProfitPage', component: FundProfitPage, route: PrivateRoute, roles: ['operator'], title: 'Profit' },
  //{ path: '/rm/userlist', name: 'UserListPage', component: UserListPage, route: PrivateRoute, roles: ['operator','supervisor'], title: 'User List' },
  //{ path: '/rm/create', exact: true, name: 'RMCreateNewPage', component: RMCreateNewPage, route: PrivateRoute, roles: ['operator'], title: 'Create New RM' },
  //{ path: '/rm/user/:id', name: 'RMUpdateUserPage', component: RMUpdateUserPage, route: PrivateRoute, roles: ['operator'], title: 'Update RM User', },
  //{ path: '/rmuser/list', name: 'RMUserListPage', component: RMUserListPage, route: PrivateRoute, roles: ['rm'], title: 'RM User List' },
  //{ path: '/rmuser/user/:id', name: 'RMUserDetailUserPage', component: RMUserDetailUserPage, route: PrivateRoute, roles: ['operator','supervisor','rm'], title: 'RM User (Single) Detail Info', },
  //{ path: '/rmuser/create', exact: true, name: 'RMUserCreateNewPage', component: RMUserCreateNewPage, route: PrivateRoute, roles: ['operator'], title: 'Create New User' },



/*
  { path: '/allaccounts', exact: true, name: 'UserMgmtAllAccounts', component: UserMgmtAllAccounts, route: PrivateRoute, roles: ['operator', 'admin'], title: 'All Accounts' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['admin','auditor'], title: 'Dashboard' },
  { path: '/user/:id', name: 'User', component: User, route: PrivateRoute, roles: ['admin','operator'], title: 'User Management', },
  { path: '/reset_user_password', exact: true, name: 'UserResetPassword', component: UserResetPassword, route: PrivateRoute, roles: ['admin','operator'], title: 'Reset Password' },
  { path: '/user', exact: true, name: 'UserMgmt', component: UserMgmt, route: PrivateRoute, roles: ['admin','operator'], title: 'User Assets' },
  { path: '/userdetail', exact: true, name: 'UserDetail', component: UserDetail, route: PrivateRoute, roles: ['admin','operator'], title: 'User Detail(SAMPLE)' },
  { path: '/bulkemail', exact: true, name: 'BulkEmail', component: BulkEmail, route: PrivateRoute, roles: ['admin','operator'], title: 'Bulk Email' },
  { path: '/report/wota', name: 'ReportWOTA', component: ReportWOTA, route: PrivateRoute, roles: ['admin','operator'], title: 'Report: WOTA' },
  // { path: '/report/100ep', name: 'Report100EP', component: Report100EP, route: PrivateRoute, roles: ['admin','operator'], title: 'Report: 100ETH Plan' },
  { path: '/transfer/deposit', name: 'TransferDeposit', component: TransferDeposit, route: PrivateRoute, roles: ['admin','operator'], title: 'Transfer to Wota' },
  { path: '/transfer/withdraw', name: 'TransferWithdraw', component: TransferWithdraw, route: PrivateRoute, roles: ['admin','operator'], title: 'Transfer from Wota' },
  { path: '/generate_form', exact: true, name: 'GenerateForm', component: GenerateForm, route: PrivateRoute, roles: ['admin','operator'], title: 'Generate Form' },
  { path: '/register/recent', name: 'RegisterRecent', component: RegisterRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Registrations' },
  { path: '/deposit/recent', name: 'DepositRecent', component: DepositRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Deposits' },
  { path: '/withdraw/recent', name: 'WithdrawRecent', component: WithdrawRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Withdrawals' },
  { path: '/withdraw/deposit_from_wota', name: 'DepositFromWota', component: DepositFromWota, route: PrivateRoute, roles: ['admin','operator'], title: 'Deposit From Wota' },
  { path: '/form/:id', name: 'Form', component: Form, route: PrivateRoute, roles: ['admin','operator'], title: 'Form Management' },
  { path: '/form', exact: true, name: 'FormPost', component: FormPost, route: PrivateRoute, roles: ['admin','operator'], title: 'Write Off/Post' },

  // Statistics pages
  { path: '/stats', exact: true, name: 'Stats', component: Stats, route: Route },
*/


  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/asset/my" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };
