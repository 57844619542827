// @flow
import {
    COMPETITION_INFO,
    COMPETITION_SUBMIT,
} from '../../constants/actionTypes';

//import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    //user: getLoggedInUser(),
    loading: false
};

type CompetitionAction = { type: string, payload: {} | string };
//type State = { user?: {} | null, loading?: boolean, +value?: boolean };
type State = { loading?: boolean, +value?: boolean };

const Competition = (state:State = INIT_STATE, action: CompetitionAction) => {
    switch (action.type) {
        case COMPETITION_INFO:
            return { ...state, loading: false };
        case COMPETITION_SUBMIT:
            return { ...state, loading: false };
        default: return { ...state };
    }
}

export default Competition;

