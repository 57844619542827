
export const API_ROOT = 'https://www.devel.w-rex.com'
//export const API_ROOT = 'https://www.staging.w-rex.com'
//export const API_ROOT = 'https://www.w-rex.com'

export const NAV_WREX_PAGE = 'https://www.devel.w-rex.com'
//export const NAV_WREX_PAGE = 'https://www.staging.w-rex.com'
//export const NAV_WREX_PAGE = 'https://www.w-rex.com'

export const WREX_PAGE = 'https://www.devel.w-rex.com'
//export const WREX_PAGE = 'https://www.staging.w-rex.com'
//export const WREX_PAGE = 'https://www.w-rex.com/index_kr.html'

//export const LOGIN_PAGE = 'http://localhost:3001/login'
export const LOGIN_PAGE = 'https://www.devel.w-rex.com/login'
//export const LOGIN_PAGE = 'https://www.staging.w-rex.com/login'
//export const LOGIN_PAGE = 'https://www.w-rex.com/login'


//export const REDIRECT_URI = 'http://localhost:3000/competition/redirect'
export const REDIRECT_URI = 'https://lounge.devel.w-rex.com/competition/redirect'
//export const REDIRECT_URI = 'https://lounge.staging.w-rex.com/competition/redirect'
//export const REDIRECT_URI = 'https://wlounge.w-rex.com/competition/redirect'

export const DEFAULT_REFERRAL_CODE = "00000000"

export const FAKE_API = false
export const API_TIMEOUT_TIME = 5000

export const MAX_DECIMAL_NUMBER=8;


