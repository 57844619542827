
export function Translations_kr(language) { 
    return { 


      ETH: 'ETH',
      did_not_play: '미참여',
      played: '참여',

      link_1: 'Daily Competition',
      link_2: 'News on Ethereum',
      link_3: 'WREX',
      link_4: '로그아웃',
      link_5: '로그인',

      today_winner_number: '당첨결과',
      today_1_eth_worth: '이더리움의 USD 가격',
      today_twn_represents_1_eth_1: '당첨번호는',
      today_twn_represents_1_eth_2: ' - 한국시간 오후 9시 기준, ',
      today_twn_represents_1_eth_3: '거래소의 USD 가격 입니다.',

      desc_title: '이더리움 맞추기 게임 참여 방법!',
      desc_content1: '✓ 매일 경품 0.05 이더리움, 매일 1회 참여 가능',
      desc_content2: '✓ 당첨자 없을 시 상금은 익일 이월',
      desc_content3: '✓ 다수 당첨자 발생시 당일 상금 N분의 1',
      desc_content4: '✓ 자세한 게임 규칙은 아래 PLAY BOOK을 참고',
      desc_content5: '✓ www.w-rex.com 회원이면 무료 참여 가능',
      desc_content6: '무료 회원 가입하기',
                                                        
                                                        
      play_book_title: '게임 상세 규칙',
      click_to_view_plus: 'more-less glyphicon kr-plus glyphicon-plus font_darkred',
      click_to_view_minus: 'more-less glyphicon kr-minus glyphicon-minus font_darkred',
      play_book_click: '게임 상세 규칙',
      play_book_rule1_title: '가. 참여 방법',
      play_book_rule1_content1: 'WREX 회원이시면 무료로 하루에 한 번씩 참여하실 수 있습니다.\n - 이미 회원이신가요? 그렇다면 www.w-rex.com 홈페이지에서 로그인 후 W라운지로 오시면 됩니다.\n - 회원이 아니신가요? www.w-rex.com을 방문하셔서 무료 회원 가입을 먼저 해 주세요.\n',
      play_book_rule1_content2: '매일 오후 6시까지 본인 만의 숫자를 반드시 접수해야만 당일 게임에 참여할 수 있습니다.\n 1일 1회 접수 가능하며, 미래의 게임을 미리 할 수 없습니다.\n\n"이더리움 가격을 정확히 맞추시면, 소정의 상품을 수령하고, 명예의 전당에 이름을 남길 수 있습니다!"',

      play_book_rule2_title: '나. 당첨결과',
      play_book_rule2_content1: '매일, 한국 시간으로 오후 9시, 홍콩소재의 BITFINEX 거래소에 1이더리움의 미국달러 가격이 "당첨결과" 로 자동 발표됩니다.',
      play_book_rule2_content2: '당첨자는 익일 오전 9시에 W라운지에 기재 됩니다.',
      play_book_rule2_content3: '',

      play_book_rule3_title: '다. 상품 당첨자 선정',
      play_book_rule3_content1: '당첨 결과에 따른 당첨자는 오늘의 상품 (이더리움)을 수령하게 됩니다.',
      play_book_rule3_content2: '당일 결과에 따른 당첨자가 없는 경우, 당일 상품은 다음 날로 이월되어 합산됩니다.',
      play_book_rule3_content3: '당일 결과에 다수의 당첨가자 생길 경우, 당일 상품은 N분의 1로 배분됩니다.\n\n상품 당첨자는 매일 오전 9시에 W라운지에 공개되며, 1달에 한 번 명예의 전당이 업데이트 됩니다.',

      play_book_rule4_title: '라. 상품 수령',
      play_book_rule4_content1: '매월 1일부터 마지막 날까지 당첨자의 상품은 다음 달 10일에 일괄적으로 정산되어 본인의 WREX 상품으로 자동 가입됩니다.',
      play_book_rule4_content2: 'WREX 상품에 가입된 이더리움은 언제든지 지갑으로 출금이 가능합니다.',
      play_book_rule4_content3: '',

      play_book_rule5_title: '마. 게임 참여 조건',
      play_book_rule5_content1: 'WREX 회원이면, 1일 1회 참여와 상품을 수령을 위한 다른 조건은 없습니다.',

      today_winner_prize: '오늘의 상품',

      submit_title: '“제 가격은요~”',
      submit_deadline: '제출 마감시간: 매일 오후 6시',
      submit_start: 'UTC 낮 12시 (한국시간 오후 9시이후) 접수할 수 있습니다.',
      submit_you_have: '금일 제출 마감시간까지',
      submit_you_to: '남았습니다.',
      submit_hours: '시간',
      submit_minutes: '분',
      submit_seconds: '초',
      submit_btn: '제출',
      submit_login_1: '제출시에는 반드시 www.w-rex.com에',
      submit_login_2: '로그인',
      submit_login_3: '이 되어 있어야 합니다.',
      submit_info: '1. 제출하실 4자리 숫자를 입력 해주세요.\n2. 아래 "제출" 버튼을 눌러주세요. 마감 전까지 여러 번 수정 제출 하실 수 있으며, 최종 제출 숫자만 유효합니다.\n3. W-REX 로그인 페이지가 나오면, 아이디/비밀번호로 로그인 해주세요.\n4. 로그인 하시면 자동으로 회원님의 번호가 접수됩니다.',

      submit_last_1: '',
      submit_last_2: '회원님',
      submit_last_3: '금일 번호는',
      submit_last_4: '로 이미 접수 완료되었습니다. 행운을 빕니다!',

      today_entry_1: '오늘 이더리움 맞추기에  참여한 인원:',
      today_entry_2: '명',

      result_title_1: '이번 달 당첨자 명단',
      result_title_2: '나의 제출 기록',
      result_field_1: '날짜',
      result_field_2: '당첨자',
      result_field_3: '당첨상품',
      result_field_4: '참가',
      result_field_5: '나의 제출 숫자',
      result_field_6: '당첨 숫자',
      result_no_record: '기록을 찾을 수 없습니다.',
      result_login_1: 'www.w-rex.com에',
      result_login_2: '로그인',
      result_login_3: '하면, 본인의 제출 기록을 볼 수 있습니다.',

      thank_you_for_playing: '회원님의 번호가 접수되었습니다. 참여해 주셔서 감사합니다.\n\n회원님의 접수된 번호는 하단의 "나의 제출 기록"에서 확인하실 수 있습니다.',

// API Error
      login_required: 'Session expire!',
      //submission_time_exceed: 'It is out of playing time. Place window is 12:00 - 09:00 UTC',
      submission_time_exceed: '금일 제출시간이 마감되었습니다.\n 내일 게임은 오후 9시~다음날 오후 6시까지 제출하면 됩니다.',

      invalid_number: 'Invalid number',

      unknown_error: 'Unknown Error',    
    } 
} 



