import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
//import userSaga from './user/saga';
//import managementSaga from './management/saga';
//import advertiserSaga from './advertiser/saga';
//import saleSaga from './sale/saga';
//import campaignSaga from './campaign/saga';
//import assetSaga from './asset/saga';
//import creativeSaga from './creative/saga';
import competitionSaga from './competition/saga';
//import dashboardSaga from './dashboard/saga';
//import transferSaga from './transfer/saga';
//import postSaga from './post/saga';
//import recentSaga from './recent/saga';
//import requestSaga from './request/saga';
//import profitSaga from './profit/saga';
//import RMSaga from './rm/saga';
//import FundSaga from './fund/saga';
//import TaskSaga from './task/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        //userSaga(),
        //managementSaga(),
        //advertiserSaga(),
        //saleSaga(),
        //campaignSaga(),
        //assetSaga(),
        //creativeSaga(),
        competitionSaga(),
        //dashboardSaga(),
        //transferSaga(),
        //postSaga(),
        //recentSaga(),
        //requestSaga(),
        //profitSaga(),
        //RMSaga(),
        //FundSaga(),
        //TaskSaga(),
    ]);
}
